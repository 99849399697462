.services_wrapper {
  @include grid-cols(4, 1fr, 2rem);
  place-items: center;
  @include padding-y(3rem, 1rem);

  @include devices(lg) {
    @include grid-cols(2, 1fr, 3rem);
  }

  @include devices(xs) {
    grid-template-columns: 1fr;
  }
}

.services_card {
  @include flexbox(flex-start, center);
  gap: 1.2rem;
}

.services_icon {
  color: var(--main-color);
  opacity: 0.9;
  font-size: 2.2rem;
  line-height: 1;
}

.services_details {
  p {
    font-size: 0.875rem;
    margin-top: 0.4rem;
    opacity: 0.8;
  }
}
