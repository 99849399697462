.add_products_container-wrapper {
  max-width: 600px;
  margin: 20px auto;
  background-color: #2a2a2a; /* Darker container background */
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Darker shadow */
}

h1 {
  text-align: center;
  margin-top: 100px;
  color: #ffffff; /* Light text color */
}

.product-image {
  text-align: center;
  margin-bottom: 20px;
}

.product-image img {
  width: 200px;
  height: 200px;
  height: auto;
  border-radius: 8px;
}

.dark-theme {
  color: #ffffff; /* Light text color */
}

.dark-theme input[type="text"],
.dark-theme input[type="number"],
.dark-theme select,
.dark-theme textarea,
.dark-theme button {
  background-color: #333333; /* Darker input background */
  color: #ffffff; /* Light text color */
  border: 1px solid #555555; /* Darker border */
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
}

.dark-theme input[type="file"] {
  margin-bottom: 10px;
}

.dark-theme button {
  background-color: #4caf50; /* Green button color */
  color: #ffffff; /* Light text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.dark-theme button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.dark-theme select {
  width: 100%;
  padding: 8px;
}

.dark-theme label {
  display: block;
  margin-bottom: 6px;
}
