.featured_wrapper {
  position: relative;
  display: grid;
  padding: 0px 4.5rem;
  width: 100%;

  @include grid-cols(5, 1fr, 0.7rem);

  @include devices(lg) {
    @include grid-cols(4, 1fr, 0.7rem);
  }

  @include devices(md) {
    @include grid-cols(3, 1fr, 0.6rem);
  }

  @include devices(xs) {
    grid-template-columns: 1fr;
  }
}

#other-collections-text {
  color: rgb(65, 65, 65);
  font-size: 20px;
  font-weight: 600;
}

.categories_card {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.featured_img {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    @include transition(transform, 0.25s, ease);
  }

  &:hover {
    transform: scale(1.05);
  }
}

.featured_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.featured_title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: x-large;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
