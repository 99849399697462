#hero {
  min-height: 100vh;
  @include centered;

  .swiper {
    @include dimension(100%, 100%);
    margin-top: 150px;
  }

  .swiper-pagination {
    bottom: 30px;
  }
}

.hero_wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  @include grid-cols(2, 0.4fr, 2rem);
  place-content: center;
  place-items: center;

  &::before {
    @include pseudo($top: 10%);
    font-size: 15rem;
    font-weight: 700;
    opacity: 0.05;

    @include devices(xl) {
      font-size: 12rem;
    }

    @include devices(sm) {
      font-size: 8rem;
    }
  }

  @include devices(lg) {
    grid-template-columns: 0.7fr;
    gap: 4rem;
  }

  @include devices(sm) {
    grid-template-columns: 0.9fr;
    gap: 2rem;
  }
}

.hero_item_img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to left,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.hero_item_txt,
.hero_item_img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero_item_txt {
  text-shadow: var(--text-shadow);
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  h1 {
    font-size: 3rem;
    text-transform: capitalize;
    word-spacing: 3px;
    text-align: center;

    @include margin-y(0.8rem, 1rem);

    @include devices(xl) {
      font-size: 3rem;
    }

    @include devices(sm) {
      font-size: 1.8rem;
    }

    @include devices(xs) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    color: rgb(220, 220, 220);

    @include devices(sm) {
      font-size: 1rem;
    }
  }
}

.hero_action_btn {
  padding: 8px 12px;
  border-radius: 28px;
  background-color: rgb(61, 61, 61);
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;

  a {
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
  }
}

.hero_item_img {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    @include img-fit;
  }

  @include devices(lg) {
    grid-row: 1;
  }

  @include devices(sm) {
    max-width: 18rem;
    width: 75%;
  }
}
