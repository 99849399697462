.top-products {
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  align-items: center;
}

.products_wrapper {
  display: grid;
  flex-grow: 1;
  grid-auto-flow: row;

  @include grid-cols(4, 1fr, 0.7rem);

  @include devices(lg) {
    @include grid-cols(3, 1fr, 0.7rem);
  }

  @include devices(md) {
    @include grid-cols(2, 1fr, 0.6rem);
  }

  @include devices(xs) {
    @include grid-cols(2, 1fr, 0.3rem);
  }
}

.products_card {
  @include bordered(1px, rgba($color: #ffffff, $alpha: 0.4));
  border-radius: 3px;
  box-shadow: 0px 5px 10px rgba($color: #000000, $alpha: 0.5);
  overflow: hidden;
  max-width: 300px;

  &.browse_card {
    @include centered;
    @include padding-y(5rem, 5rem);
    font-size: 1.5rem;

    a {
      @include transition(color, 0.3s);

      &:hover {
        color: #eee;
      }
    }
  }
}

.products_img {
  background: var(--bg-color-2);
  text-align: center;
  padding: 1rem;
  overflow: hidden;
  max-width: 300px;
  aspect-ratio: 1 / 1;

  .container {
    flex-grow: 1;
    padding: 2em;
    // background-image: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8)),
    //   url("/public/banner.png");
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .title {
    font-size: 3em;
  }
  .info {
    font-size: 1.1em;
    letter-spacing: 1px;
    line-height: 1.5;
    margin: 1.5em;
    color: rgb(224, 224, 224);
  }
  .btn {
    margin: 0 auto;
    border: none;
    outline: none;
    padding: 0.75em 1em;
    font-size: 1em;
    letter-spacing: 1px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: rgb(134, 49, 0);
    color: #fff;
  }

  .sidebar {
    width: 260px;
    flex-shrink: 0;
    background-color: rgba(22, 22, 22, 1);
    height: 100%;
    overflow: auto;
  }

  .sidebar-item {
    padding: 0.75em 1em;
    display: block;
    transition: background-color 0.15s;
    border-radius: 5px;
  }
  .sidebar-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .sidebar-title {
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
  }
  .sidebar-title span i {
    display: inline-block;
    width: 1.5em;
  }
  .sidebar-title .toggle-btn {
    cursor: pointer;
    transition: transform 0.3s;
  }
  .sidebar-item.open > .sidebar-title .toggle-btn {
    transform: rotate(180deg);
  }
  .sidebar-content {
    padding-top: 0.25em;
    height: 0;
    overflow: hidden;
  }
  .sidebar-item.open > .sidebar-content {
    height: auto;
  }

  .sidebar-item.plain {
    color: #fff;
    text-decoration: none;
  }
  .sidebar-item.plain:hover {
    text-decoration: underline;
  }
  .sidebar-item.plain i {
    display: inline-block;
    width: 1.7em;
  }
  img {
    width: 70%;
    @include transition(transform, 0.25s, ease);

    &:hover {
      transform: scale(1.05);
    }
  }
}

.products_details {
  padding: 1rem;
}

.products_title {
  @include margin-y(0.8rem, 0.4rem);
}

.products_info {
  opacity: 0.8;
  font-weight: 500;
}

.products_btn {
  width: 100%;
  margin-top: 1.2rem;

  &.active {
    background-color: green;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

/*------ All-Products Page ------*/
#all_products {
  padding-top: 8rem;
  display: grid;
  grid-template-columns: 240px 1fr;

  @include devices(lg) {
    grid-template-columns: 1fr;
  }

  .products_wrapper {
    @include devices(xl) {
      @include grid-cols(3, 1fr, 0.7rem);
    }

    @include devices(md) {
      @include grid-cols(2, 1fr, 0.6rem);
    }

    @include devices(xs) {
      grid-template-columns: 1fr;
    }
  }
}
